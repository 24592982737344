import { gql } from '@apollo/client';

import { HeadlessParent } from '../headlessProviderTypes';

export interface DiscountData {
  discountCode: string;
  discountPrice: string;
}

export const DISCOUNT_QUERY = gql`
  query LearnMarketingModalCta {
    learnMarketingModal {
      headline
      benefitsList
      detailsLink
      detailsText
      discountCode
      discountPrice
      price
      priceDuration
    }
    cta: learnCta {
      buttonText
      description
      headline
      priceDisclaimer
      priceDiscount
    }
  }
`;

export const discountQueries = {
  [HeadlessParent.OUTSIDE_WATCH.toString()]: {
    query: gql`
      query WatchMarketingModal {
        watchMarketingModal {
          discountCode
          discountPrice
        }
      }
    `,
    wrapperFieldName: 'watchMarketingModal'
  },
  [HeadlessParent.OUTSIDE_LEARN.toString()]: {
    query: DISCOUNT_QUERY,
    wrapperFieldName: 'learnMarketingModal'
  }
};
