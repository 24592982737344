import {
  AuthEventID,
  OutsideHeaderEventDomain
} from '@outside-interactive/web-microfrontends.helpers.types';

import { UXEventID } from './types';
import { HEADER_KEY } from './constants';

const JOIN_NOW_URL = new URL('https://www.outsideonline.com/outsideplus');

export const openProfileInformation = () => {
  if (typeof window !== 'undefined' && window?.OutsideHeader?.dispatch) {
    window.OutsideHeader.dispatch(HEADER_KEY, {
      domain: OutsideHeaderEventDomain.UX,
      id: UXEventID.OPEN_PROFILE_INFORMATION
    });
  }
};

export const openMembershipProfile = () => {
  if (typeof window !== 'undefined' && window?.OutsideHeader?.dispatch) {
    window.OutsideHeader.dispatch(HEADER_KEY, {
      domain: OutsideHeaderEventDomain.UX,
      id: UXEventID.OPEN_PROFILE_MEMBERSHIP
    });
  }
};

export const closeProfileOverlay = () => {
  if (typeof window !== 'undefined' && window?.OutsideHeader?.dispatch) {
    window.OutsideHeader.dispatch(HEADER_KEY, {
      domain: OutsideHeaderEventDomain.UX,
      id: UXEventID.CLOSE_PROFILE_OVERLAY
    });
  }
};

export const toggleMegaNav = () => {
  if (typeof window !== 'undefined' && window?.OutsideHeader?.dispatch) {
    window.OutsideHeader.dispatch(HEADER_KEY, {
      domain: OutsideHeaderEventDomain.UX,
      id: UXEventID.TOGGLE_MEGA_NAV
    });
  }
};

export const toggleProfileStub = () => {
  if (typeof window !== 'undefined' && window?.OutsideHeader?.dispatch) {
    window.OutsideHeader.dispatch(HEADER_KEY, {
      domain: OutsideHeaderEventDomain.UX,
      id: UXEventID.TOGGLE_PROFILE_STUB
    });
  }
};

export const joinOutside = () => {
  /*
              If Outside-auth-header is not available, this callback should redirect
              to a register page. This is made as to not introduce breaking changes between
              Learn and Watch platform as we transition from outside-auth-header to AuthService.
               */
  if (typeof window !== 'undefined' && window?.OutsideHeader?.dispatch) {
    window.OutsideHeader.dispatch(HEADER_KEY, {
      domain: OutsideHeaderEventDomain.UX,
      id: UXEventID.TRIGGER_JOIN_NOW
    });
  } else window.location.href = JOIN_NOW_URL.href;
};

export const setDiscount = (discountCode: string) => {
  if (typeof window !== 'undefined' && window?.OutsideHeader?.dispatch) {
    window.OutsideHeader.dispatch(HEADER_KEY, {
      domain: OutsideHeaderEventDomain.AUTH,
      id: AuthEventID.SET_DISCOUNT,
      payload: {
        discountCode
      }
    });
  }
};

export const openPayment = () => {
  if (typeof window !== 'undefined' && window?.OutsideHeader?.dispatch) {
    window.OutsideHeader.dispatch(HEADER_KEY, {
      domain: OutsideHeaderEventDomain.UX,
      id: UXEventID.OPEN_PAYMENT
    });
  }
};
