import {
  AnalyticsEvent,
  AuthEvent,
  OutsideHeaderEvent,
  OutsideHeaderEventDomain
} from '@outside-interactive/web-microfrontends.helpers.types';

// we have to update UXEventID enum and UXEvent type to add new actions to headerEvents

export enum UXEventID {
  OPEN_PROFILE_INFORMATION,
  OPEN_PROFILE_MEMBERSHIP,
  CLOSE_PROFILE_OVERLAY,
  TOGGLE_MEGA_NAV,
  TOGGLE_PROFILE_STUB,
  TRIGGER_JOIN_NOW,
  OPEN_BOOKMARKS,
  CLEAR_VIEW,
  OPEN_PAYMENT
}

export declare type UXEvent = {
  domain: OutsideHeaderEventDomain;
  id: UXEventID;
};

// we have to update EventTypeGuard and DispatchFunction with new types to be able to dispatch new actions

export declare type EventTypeGuard = AuthEvent | AnalyticsEvent | UXEvent;

export declare type DispatchFunction = (
  key: string,
  event: OutsideHeaderEvent<EventTypeGuard>
) => void;
