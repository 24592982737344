import { NextSeoProps } from 'next-seo/lib/types';
import { SEO } from 'types';
import { APPLICATION_URL } from 'lib/constants';

export function parsePageSeo({
  asPath,
  ...seo
}: SEO & { asPath: string }): NextSeoProps {
  const {
    title,
    description,
    ogTitle,
    ogDescription,
    ogImage,
    ogImageWidth,
    ogImageHeight
  } = seo || {};

  return {
    canonical: `${APPLICATION_URL}${asPath.split('?')[0]}`,
    title,
    description,
    noindex: process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production',
    nofollow: process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production',
    openGraph: {
      title: ogTitle,
      description: ogDescription,
      images: [
        {
          url: ogImage,
          width: ogImageWidth,
          height: ogImageHeight
        }
      ]
    }
  };
}
